import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  DotsLoader,
  FormInput,
  FormSelectInput,
} from "../../components";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { get_states } from "../../features/countriesSlice";
import { create_user, update_user } from "../../features/usersSlice";
import FormRadioGroup from "../../components/molecules/FormRadioGroup";
import { toast } from "react-toastify";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import { userPermissionNew } from "../../util/userPermissionNew";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PhoneInput from "react-phone-number-input";
import { MultiSelect } from "primereact/multiselect";
import RolesAssignModal from "./RolesAssignModal";
import UserCompanyEmail from "./ProfilePassword/UserCompanyEmail";
const libraries = ["places", "drawing", "geometry"];
const AddUpdateForm = ({ editingRecord, onCancelForm, modalTitle }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_API,
    libraries,
  });
  const [countryCode, setCountryCode] = useState("US");
  const [autocomplete, setAutocomplete] = useState();
  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };
  const defaultProps = {
    zoom: 15,
  };
  const [coords, setCoords] = useState({
    lat: 10.99835602,
    lng: 77.01502627,
  });
  const onChangeAddress = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const lat = place?.geometry?.location.lat();
      const lng = place?.geometry?.location.lng();

      formik?.setFieldValue(`address`, place?.formatted_address);
      formik?.setFieldValue(`address_latitude`, lat.toString());
      formik?.setFieldValue(`address_longitude`, lng.toString());

      extractAddress(place);
    } else {
      console.warn("Autocomplete is not loaded yet!");
    }
  };
  const extractAddress = (place) => {
    if (!Array.isArray(place?.address_components)) {
      return null;
    }

    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component;

      if (types.includes("postal_code")) {
        formik.setFieldValue(`zip`, value?.long_name);
      }

      if (types.includes("locality")) {
        formik.setFieldValue(`city`, value?.long_name);
      }
      if (types.includes("administrative_area_level_1")) {
        formik.setFieldValue(`state_id`, value?.short_name);
      }

      if (types.includes("country")) {
        formik.setFieldValue(
          `country_id`,
          countries?.find((country) => country.code === value?.short_name)._id
        );
      }
    });
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setCoords({ lat, lng });
  };

  const dispatch = useDispatch();

  const { isLoading, record } = useSelector((state) => state.roles);
  const inbounds = useSelector((state) => state.inbound);
  const merchant = useSelector((state) => state.merchant);
  const { countries, states, isLoader } = useSelector(
    (state) => state.countries
  );
  const users = useSelector((state) => state.users);
  const [errors, setErrors] = React.useState([]);
  const [showPass, setShowPass] = React.useState(false);
  const [isRolesAssign, setIsRolesAssign] = React.useState(false);
  let initialValues = {
    first_name: "",
    email: "",
    username: "",
    // role_id: "",
    country_id: "",
    state_id: "",
    merchants: [],
    last_name: "",
    fax: "",
    address: "",
    street: "",
    zip: "",
    city: "",
    ip_filtering: false,
    phone: "",
    is_dialer_user: false,
    address_latitude: "",
    address_longitude: "",
    primary_merchant_id: "",
    country_short_code: "US",
    closer_campaigns: [],
    // permission_roles: [],
  };

  if (editingRecord) {
    const {
      _id,
      first_name,
      email,
      // role_id,
      last_name,
      fax,
      address,
      street,
      zip,
      city,
      ip_filtering,
      phone,
      country_id,
      state_id,
      is_dialer_user,
      address_latitude,
      address_longitude,
      pswrd,
      active,
      username,
      primary_merchant_id,
      client_phone_masking,
      merchants,
      dialer_user,
      // permission_roles,
    } = editingRecord;
    const selectedInbounds = inbounds?.inboundDrd?.filter((obj) =>
      editingRecord?.dialer_user?.closer_campaigns?.includes(obj.group_id)
    );
    initialValues = {
      id: _id,
      first_name,
      email,
      // role_id: role_id?._id,
      country_id: country_id?._id,
      state_id: state_id?._id,
      last_name: last_name || "",
      fax: fax,
      address: address,
      street: street,
      zip: zip,
      city: city,
      ip_filtering: ip_filtering,
      phone: phone,
      is_dialer_user,
      address_latitude,
      address_longitude,
      pswrd,
      active,
      username,
      primary_merchant_id: primary_merchant_id?._id,
      client_phone_masking,
      country_short_code: country_id?.code,
      merchants: [...merchants],
      closer_campaigns: selectedInbounds?.map(({ group_id }) => group_id),
      dialer_user: dialer_user?.dialer_user,
      dialer_phone: dialer_user?.dialer_phone,
      call_center_role: dialer_user?.dialer_role,
      // permission_roles,
    };
  }

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,
    // validationSchema: UserSchema,
    onSubmit: async (values) => {
      const payload = { ...values };
      delete payload.dialer_user;
      delete payload.dialer_phone;
      delete payload.dialer_role;
      if (!payload.is_dialer_user) {
        delete payload.call_center_role;
      }
      if (!editingRecord) {
        try {
          const res = await dispatch(
            create_user({
              ...payload,
            })
          );
          if (res?.payload?.status === 200) {
            toast.success("User created");
            onCancelForm();
          } else {
            if (Array.isArray(res.payload)) {
              setErrors(res.payload);
            } else {
              toast.error(
                res.payload ? res.payload : "User couldn't be created"
              );
            }
          }
        } catch (error) {
          console.error(
            "ERROR ADD USER ~ file: AddUpdateForm.jsx ~ line 79 ~ onSubmit: ~ error",
            error
          );

          toast.error("Error, User couldn't be created");
        }
      } else {
        const newObj = { ...payload };
        delete newObj.username;
        try {
          const res = await dispatch(
            update_user({
              ...newObj,
              // role_id: values?.permission_roles?.length
              //   ? values?.permission_roles[0]
              //   : "",
            })
          );
          if (res?.payload?.status === 200) {
            toast.success("User updated");
            onCancelForm();
          } else {
            if (Array.isArray(res.payload)) {
              setErrors(res.payload);
            } else {
              toast.error(
                res.payload ? res.payload : "User couldn't be updated"
              );
            }
          }
        } catch (error) {
          console.error(
            "ERROR ADD USER ~ file: AddUpdateForm.jsx ~ line 79 ~ onSubmit: ~ error",
            error
          );
          error?.forEach((error) => {
            const field = Object.keys(error)[0]; // get the field name from the error object
            formik.setErrors(field, error[field]); // set the error for the field
          });
        }
      }
    },
  });

  const handleMerchantChange = (selectedMerchantIds) => {
    const updatedMerchants = selectedMerchantIds.map((id) => {
      const existingMerchant = formik.values.merchants.find(
        (m) => m.merchant_id === id
      );
      return existingMerchant || { merchant_id: id, role_id: "" };
    });

    formik.setFieldValue("merchants", updatedMerchants);
  };
  const handleState = async () => {
    if (!editingRecord && formik.values.state_id) {
      formik.setFieldValue(
        `state_id`,
        states?.find((state) => state.code === formik.values.state_id)?._id
      );
    } else if (formik.values.state_id) {
      formik.setFieldValue(
        `state_id`,
        states?.find((state) => state._id === formik.values.state_id)?._id
      );
    }
  };
  useEffect(() => {
    const { country_id } = formik.values;
    if (country_id) {
      handleState();
    }
    // eslint-disable-next-line
  }, [formik.values.country_id]);
  useEffect(() => {
    if (formik.values.country_id) {
      dispatch(get_states(formik.values.country_id));
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setCountryCode(
      countries?.find(({ _id }) => _id === formik.values.country_id)?.code
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country_id]);
  useEffect(() => {
    if (
      record?.find(({ _id }) => _id === formik.values.role_id)?.name === "Tech"
    ) {
      formik.setFieldValue("is_dialer_user", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.role_id]);
  function findPhoneValue() {
    for (let i = 0; i < errors.length; i++) {
      if ("phone" in errors[i]) {
        return errors[i].phone;
      }
    }
    return null; // Return null if "phone" property is not found in any object
  }
  const phoneErrorValue = findPhoneValue();
  function hasRoleId(objects) {
    return objects.every((obj) => obj.role_id);
  }
  return (
    <>
      <div className="min-h-screen bg-white w-full z-[100] fixed top-0 left-0 overflow-y-auto">
        <div className="overflow-y-auto h-screen">
          {isRolesAssign ? (
            <RolesAssignModal
              onCancelForm={() => setIsRolesAssign(false)}
              formik={formik}
            />
          ) : null}
          <div className="flex items-center justify-between w-full text-client-50 py-2.5 px-5 bg-white shadow-client overflow-y-auto">
            <h4>{modalTitle}</h4>
            <Button
              text="X"
              className="mr-2"
              onClick={onCancelForm}
              variant="btn_danger"
            />
          </div>
          <Backdrop onClick={onCancelForm} />
          {isLoader || isLoading || users.isLoading ? <DotsLoader /> : null}

          <form className="p-3 grid gap-3 md:grid-cols-2 mb-[15vh]">
            <div className="py-3 px-10 bg-white rounded-3xl shadow-client">
              <h5 className="text-client-50 pb-2.5 w-full border-b border-client-50">
                Basic Information
              </h5>
              <div className="grid md:grid-cols-2 gap-x-5">
                <div className="mt-3">
                  <FormInput
                    errors={errors}
                    name="first_name"
                    label="First Name"
                    formik={formik}
                  />
                </div>
                <div className="mt-3">
                  <FormInput
                    errors={errors}
                    name="last_name"
                    label="Last Name"
                    formik={formik}
                  />
                </div>
                <div className="mt-2">
                  <PhoneInput
                    defaultCountry={countryCode}
                    className={[
                      " appearance-none border border-[#a9a9a9] rounded max-[400px]:w-[100%] min-[400px]:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none",
                      `rounded-l-none`,
                      formik.errors.phone && formik.touched.phone
                        ? "invalid"
                        : "",
                    ].join(" ")}
                    onChange={(e) => formik.setFieldValue("phone", e)}
                    value={formik.values.phone}
                    placeholder={"Phone"}
                    initialValueFormat="national"
                    onCountryChange={(e) =>
                      formik.setFieldValue("country_short_code", e)
                    }
                  />
                  {phoneErrorValue ? (
                    <small
                      style={{
                        fontSize: 12,
                        marginTop: -5,
                        paddingLeft: 16,
                        color: "#D32F2F",
                      }}
                    >
                      {phoneErrorValue
                        ?.replaceAll(`"phone"`, "Phone")
                        ?.replaceAll(`phone`, "Phone")}
                    </small>
                  ) : null}
                </div>
                <div className="mt-2">
                  <FormInput
                    errors={errors}
                    name="fax"
                    label="Fax"
                    formik={formik}
                  />
                </div>
                <div className="mt-2">
                  <FormInput
                    errors={errors}
                    name="email"
                    label="Email"
                    formik={formik}
                  />
                </div>
                <div className="mt-2">
                  <FormInput
                    errors={errors}
                    name="username"
                    label="Username"
                    formik={formik}
                    disabled={!!editingRecord}
                  />
                </div>
                {!!editingRecord && (
                  <>
                    <div className="mt-2 relative">
                      <FormInput
                        errors={errors}
                        name="pswrd"
                        label="Password"
                        formik={formik}
                        type={showPass ? "text" : "password"}
                        disabled={!editingRecord}
                      />
                      <div
                        className="absolute top-[8px] !right-2 border-l border-[#dee2e6] rounded flex justify-center items-center h-[25px] !px-1.5 cursor-pointer"
                        onClick={() => setShowPass(!showPass)}
                      >
                        {showPass ? (
                          <FaEye className="text-black" />
                        ) : (
                          <FaEyeSlash className="text-black" />
                        )}
                      </div>
                    </div>

                    <div className="my-1.5">
                      <FormSelectInput
                        errors={errors}
                        name="active"
                        label="Active"
                        formik={formik}
                        options={[
                          { value: "true", label: "Yes" },
                          { value: "false", label: "No" },
                        ]}
                        convertor={(value) => value === "true"}
                        valueProp="value"
                        labelProp="label"
                        disabled={!editingRecord}
                      />
                    </div>
                  </>
                )}
                <div className="mt-2 relative">
                  <FormRadioGroup
                    errors={errors}
                    name="ip_filtering"
                    label="IP Filtering"
                    labelProp="label"
                    valueProp="value"
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    // eslint-disable-next-line
                    convertor={(value) => value == "true"}
                    formik={formik}
                    type="text"
                    isHorizontal
                    isBorder
                    isFloat
                  />
                </div>

                <div className="mt-2">
                  <MultiSelect
                    placeholder="Select Merchants"
                    options={merchant?.record?.records}
                    optionLabel="name"
                    optionValue="_id"
                    filter
                    maxSelectedLabels={2}
                    value={formik.values.merchants?.map(
                      ({ merchant_id }) => merchant_id
                    )}
                    onChange={(e) => handleMerchantChange(e.value)}
                    className="h-[38px] mb-2 w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                  />
                  {errors !== undefined &&
                    errors?.length > 0 &&
                    errors?.map((error, index) => {
                      return (
                        <>
                          {error["merchants"] && (
                            <small
                              style={{
                                fontSize: 12,
                                marginTop: -10,
                                paddingLeft: 16,
                                color: "#D32F2F",
                                background: "transparent",
                              }}
                              key={index}
                            >
                              {error["merchants"]}
                            </small>
                          )}
                        </>
                      );
                    })}
                </div>
                <div className="mt-2">
                  <FormSelectInput
                    errors={errors}
                    name="primary_merchant_id"
                    label="Primary Merchant"
                    formik={formik}
                    options={merchant?.record?.records}
                    valueProp="_id"
                    labelProp="name"
                  />
                </div>

                {editingRecord ? (
                  <div className="mt-2 relative">
                    <FormRadioGroup
                      errors={errors}
                      name="client_phone_masking"
                      label="Client Phone Masking"
                      labelProp="label"
                      valueProp="value"
                      options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                      // eslint-disable-next-line
                      convertor={(value) => value == "true"}
                      formik={formik}
                      type="text"
                      isHorizontal
                      isBorder
                      isFloat
                    />
                  </div>
                ) : null}
                {record?.find(({ _id }) => _id === formik.values.role_id)
                  ?.name === "Tech" ? null : (
                  <div className="mt-2 relative">
                    <FormRadioGroup
                      errors={errors}
                      name="is_dialer_user"
                      label="Dialer User"
                      labelProp="label"
                      valueProp="value"
                      options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                      // eslint-disable-next-line
                      convertor={(value) => value == "true"}
                      formik={formik}
                      type="text"
                      isHorizontal
                      isBorder
                      isFloat
                    />
                  </div>
                )}
                {formik.values.merchants?.length &&
                hasRoleId(formik.values.merchants) ? (
                  <div className="py-1 leading-3 flex justify-center flex-col">
                    <span className="text-green-600 text-sm">{`Roles are assigned to all merchants.`}</span>
                    <button
                      type="button"
                      className="text-xs hover:underline text-blue-500 text-left"
                      onClick={() => setIsRolesAssign(true)}
                    >
                      Click here to change the roles
                    </button>
                  </div>
                ) : formik.values.merchants?.length &&
                  !hasRoleId(formik.values.merchants) ? (
                  <div className="py-1 leading-3 flex justify-center flex-col">
                    <span className="text-red-600 text-sm">{`Roles are not assigned to all merchants.`}</span>
                    <button
                      type="button"
                      className="text-xs hover:underline text-blue-500"
                      onClick={() => setIsRolesAssign(true)}
                    >
                      Click here to assign roles
                    </button>
                  </div>
                ) : null}
                {formik.values.is_dialer_user ? (
                  <div className="!py-5 bg-white col-span-2">
                    <h5 className="!mb-3 text-client-50 pb-2.5 w-full border-b border-client-50">
                      Call Center Settings
                    </h5>
                    <div className="grid md:grid-cols-2 gap-x-5 !mt-5">
                      <div className="mt-2">
                        <MultiSelect
                          placeholder="Select Inbounds"
                          options={inbounds?.inboundDrd}
                          optionLabel="group_name"
                          optionValue="group_id"
                          filter
                          maxSelectedLabels={2}
                          value={formik.values.closer_campaigns}
                          onChange={(e) =>
                            formik.setFieldValue("closer_campaigns", e.value)
                          }
                          className="h-[38px] mb-2 w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                        />
                      </div>
                      <div className="mt-2">
                        <FormSelectInput
                          errors={errors}
                          name="call_center_role"
                          label="Call Center Role"
                          formik={formik}
                          options={[
                            { label: "Agent", value: 1 },
                            { label: "Admin", value: 8 },
                          ]}
                          valueProp="value"
                          labelProp="label"
                        />
                      </div>
                      <div className="mt-2">
                        <FormInput
                          errors={errors}
                          name="dialer_user"
                          label="Dialer User"
                          formik={formik}
                          readOnly={true}
                        />
                      </div>
                      <div className="mt-2">
                        <FormInput
                          errors={errors}
                          name="dialer_phone"
                          label="Dialer Phone"
                          formik={formik}
                          readOnly={true}
                        />
                      </div>
                      {/* <div className="mt-2">
                        <FormInput
                          errors={errors}
                          name="dialer_role"
                          label="Dialer Role"
                          formik={formik}
                          readOnly={true}
                        />
                      </div> */}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="py-3 px-10 bg-white rounded-3xl shadow-client">
              <h5 className=" mb-3 text-client-50 pb-2.5 w-full border-b border-client-50">
                Address Information
              </h5>
              {isLoaded && (
                <GoogleMap
                  center={coords}
                  zoom={defaultProps.zoom}
                  mapContainerStyle={{ width: "100%", height: "32vh" }}
                  options={{
                    zoomControl: false,
                    fullscreenControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                  }}
                >
                  <Marker position={coords} />
                </GoogleMap>
              )}

              <div className="grid md:grid-cols-2 gap-x-5">
                {isLoaded && (
                  <div className="mt-2">
                    <Autocomplete
                      onPlaceChanged={onChangeAddress}
                      onLoad={(autocomplete) => onLoad(autocomplete)}
                    >
                      <FormInput
                        errors={errors}
                        name="address"
                        label="Address"
                        formik={formik}
                      />
                    </Autocomplete>
                  </div>
                )}

                <div className="mt-2">
                  <FormInput
                    errors={errors}
                    name="street"
                    label="Street"
                    formik={formik}
                  />
                </div>
                <div className="mt-2">
                  <FormInput
                    errors={errors}
                    name="zip"
                    label="Zip"
                    formik={formik}
                  />
                </div>
                <div className="mt-2">
                  <FormInput
                    errors={errors}
                    name="city"
                    label="City"
                    formik={formik}
                  />
                </div>
                <div className="mt-2">
                  <FormSelectInput
                    errors={errors}
                    name="country_id"
                    label="Country"
                    formik={formik}
                    options={countries}
                    valueProp="_id"
                    labelProp="name"
                    onChange={(value) => {
                      formik.setFieldValue("country_id", value);
                      dispatch(get_states(value));
                    }}
                  />
                </div>
                <div className="mt-2">
                  <FormSelectInput
                    errors={errors}
                    name="state_id"
                    label="State"
                    formik={formik}
                    options={states}
                    valueProp="_id"
                    labelProp="name"
                  />
                </div>
                {userPermissionNew("Allow Company Email") &&
                editingRecord &&
                editingRecord?.company_email ? (
                  <UserCompanyEmail
                    companyEmail={editingRecord?.company_email}
                    editingRecord={editingRecord}
                  />
                ) : null}
              </div>
            </div>
          </form>
          <div className="z-[10] w-full absolute flex justify-center bottom-0 p-3 bg-white border-t">
            <Button
              text="Cancel"
              className="mr-2"
              onClick={onCancelForm}
              variant="btn_cancel"
            />
            <Button
              text={"Submit"}
              className="mr-2"
              onClick={formik.handleSubmit}
              variant="btn_submit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUpdateForm;
