import React, { useEffect, useState } from "react";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import api from "../../services/api";
const StateListing = ({ countryId }) => {
  const [record, setRecord] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false, minWidth: 20 },
    { headerName: "State Name", field: "name", flex: 1 },
    {
      headerName: "Country",
      field: "country_name",
      flex: 1,
    },
    { headerName: "Short Name", field: "short_name", flex: 1 },
    { headerName: "Latitude", field: "latitude", flex: 1 },
    { headerName: "Longitude", field: "longitude", flex: 1 },
    { headerName: "Status", field: "active", flex: 1 },
  ];
  const getCountriesList = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`api/location_db/states/${countryId}`);
      if (res?.status === 200 || res?.status === 201) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error || "An error occurred");
      } else {
        toast.error(err?.response?.data || "An error occurred");
      }
    }
  };
  useEffect(() => {
    getCountriesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listing = record?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  return (
    <>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={listing?.map((record, index) => {
            let counter = index + 1;
            const {
              _id,
              name,
              short_name,
              latitude,
              longitude,
              country_id,
              active,
            } = record;
            return {
              counter,
              records: { ...record },
              _id,
              name,
              short_name,
              latitude,
              longitude,
              country_id,
              country_name: country_id?.name || "N/A",
              active: active ? "Active" : "InActive",
            };
          })}
          totalItems={record?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
          showCount="No"
        />
      </div>
    </>
  );
};

export default StateListing;
