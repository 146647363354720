import React, { useEffect, useState } from "react";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { get_countries } from "../../features/countriesSlice";
const CountryListing = () => {
  const navigate = useNavigate();
  const [record, setRecord] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const columnDefs = [
    { headerName: "#", field: "counter", filterable: false, minWidth: 20 },
    { headerName: "Country Name", field: "name", flex: 1 },
    { headerName: "Code", field: "code", flex: 1 },
    { headerName: "Status", field: "active", flex: 1 },
    {
      field: "Actions",
      filterable: false,
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return params?.row?._id ? (
          <span
            onClick={() => handleStates(params.row._id)}
            className="text-blue-600 cursor-pointer hover:underline"
          >
            View States
          </span>
        ) : (
          "N/A"
        );
      },
    },
  ];

  const handleStates = (recordId) => {
    navigate(`/geolocation/geo_countries/${recordId}`);
  };

  const getCountriesList = async () => {
    setIsLoading(true);
    try {
      const res = await dispatch(get_countries());
      if (res.payload?.status === 200 || res.payload?.status === 201) {
        setRecord(res.payload.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      if (err?.response?.data?.error) {
        toast.error(err?.response?.data?.error || "An error occurred");
      } else {
        toast.error(err?.response?.data || "An error occurred");
      }
    }
  };
  useEffect(() => {
    getCountriesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listing = record?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  return (
    <>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={listing?.map((record, index) => {
            let counter = index + 1;
            const { _id, name, code, active } = record;
            return {
              counter,
              records: { ...record },
              _id,
              name,
              code,
              active: active ? "Active" : "InActive",
            };
          })}
          totalItems={listing?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          pagination="No"
          isLoading={isLoading}
          showCount="No"
        />
      </div>
    </>
  );
};

export default CountryListing;
