import React from "react";
import PageHeader from "../../components/molecules/PageHeader";
import { useParams } from "react-router-dom";
import CityListing from "./CityListing";
import Zips from "./Zips";

const GeoCities = () => {
  const { cityId } = useParams();
  return (
    <>
      <PageHeader heading={!cityId ? `City Listing` : `Zip Listing`} />
      {!cityId ? <CityListing /> : <Zips cityId={cityId} />}
    </>
  );
};

export default GeoCities;
