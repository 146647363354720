import api from "./api";

export const apiGetZipByIds = async (data) => {
  const { country_id, state_id, city_id } = data;
  let url = `/api/location_db/city_zips/${country_id}`;
  if (state_id) {
    url += `/${state_id}`;
  }
  if (city_id) {
    url += `/${city_id}`;
  }
  return await api.get(url);
};
