import React from "react";
import PageHeader from "../../components/molecules/PageHeader";
import { useParams } from "react-router-dom";
import CityListing from "./CityListing";
import StateListing from "./StateListing";

const GeoCountries = () => {
  const { stateId } = useParams();
  return (
    <>
      <PageHeader heading={!stateId ? `State Listing` : `City Listing`} />
      {!stateId ? <StateListing /> : <CityListing stateId={stateId} />}
    </>
  );
};

export default GeoCountries;
