import api from "./api";

export const getUsers = async (data) => {
  return await api.get(`/api/users`, {
    params: data,
    // params: {...data, size:1000},
  });
};
export const getUsersDrd = async (data) => {
  return await api.get(`/api/users/drd`, {
    params: data,
  });
};
export const getTech = async (data) => {
  return await api.post(`/api/users/tech`, data);
};
export const getVendorsForJobs = async (data) => {
  return await api.post(`/api/cms/vendor_by_radius/404`, data);
};
export const createUser = async (values) => {
  return await api.post("/api/users", values);
};

export const updateUser = async (values) => {
  return await api.put("/api/users", values);
};

export const deleteUser = async (id) => {
  return await api.delete("/api/users", { data: { id } });
};
export const getCRMVendorsDrd = async () => {
  return await api.get(`/api/cms/vendor/drd`);
};
