import React from "react";
import PageHeader from "../../components/molecules/PageHeader";
import { useParams } from "react-router-dom";
import CountryListing from "./CountryListing";
import StateListing from "./StateListing";

const GeoCountries = () => {
  const { countryId } = useParams();
  return (
    <>
      <PageHeader heading={!countryId ? `Country Listing` : `State Listing`} />
      {!countryId ? <CountryListing /> : <StateListing countryId={countryId} />}
    </>
  );
};

export default GeoCountries;
